export class BeforeAfter {
  constructor(entryObject) {
    this.beforeAfterContainer = document.querySelector(entryObject.id);
    if (this.beforeAfterContainer) {
      this.init();
    }
  }

  init() {
    this.before = this.beforeAfterContainer.querySelector('.bal-before');
    this.handle = this.beforeAfterContainer.querySelector('.bal-handle');
    this.resizeHandler = this.updateInsetWidth.bind(this);
    this.touchMoveHandler = this.onTouchMove.bind(this);
    this.mouseMoveHandler = this.onMouseMove.bind(this);

    // 初始化宽度
    this.updateInsetWidth();
    window.addEventListener('resize', this.resizeHandler);

    this.before.setAttribute('style', 'width: 50%;');
    this.handle.setAttribute('style', 'left: 50%;');

    // 添加事件监听
    this.beforeAfterContainer.addEventListener('touchstart', this.onTouchStart.bind(this));
    this.beforeAfterContainer.addEventListener('mousemove', this.mouseMoveHandler);
  }

  updateInsetWidth() {
    this.beforeAfterContainer
            .querySelector('.bal-before-inset')
            .setAttribute('style', 'width: ' + this.beforeAfterContainer.offsetWidth + 'px;');
  }

  onTouchStart(e) {
    this.beforeAfterContainer.addEventListener('touchmove', this.touchMoveHandler);
  }

  onTouchMove(e2) {
    let containerWidth = this.beforeAfterContainer.offsetWidth;
    let currentPoint = e2.changedTouches[0].clientX;
    let startOfDiv = this.beforeAfterContainer.offsetLeft;
    let modifiedCurrentPoint = currentPoint - startOfDiv;

    if (
            modifiedCurrentPoint > 10 &&
            modifiedCurrentPoint < this.beforeAfterContainer.offsetWidth - 10
    ) {
      let newWidth = (modifiedCurrentPoint * 100) / containerWidth;
      this.before.setAttribute('style', 'width:' + newWidth + '%;');
      this.handle.setAttribute('style', 'left:' + newWidth + '%;');
    }
  }

  onMouseMove(e) {
    e.preventDefault();
    let containerWidth = this.beforeAfterContainer.offsetWidth;
    let widthChange = e.offsetX;
    let newWidth = (widthChange * 100) / containerWidth;

    if (e.offsetX >= 0 && e.offsetX <= this.beforeAfterContainer.offsetWidth) {
      let width = newWidth > 99 ? 100 : newWidth;
      this.before.setAttribute('style', 'width:' + width + '%;');
      this.handle.setAttribute('style', 'left:' + width + '%;');
    }
  }

  destroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.resizeHandler);
    this.beforeAfterContainer?.removeEventListener('touchstart', this.onTouchStart);
    this.beforeAfterContainer?.removeEventListener('touchmove', this.touchMoveHandler);
    this.beforeAfterContainer?.removeEventListener('mousemove', this.mouseMoveHandler);
  }
}
export const SOFT_CODE = 'image_enhance_wuxi'
export const SUB_CODE = 'default'
export const SOFT_CHANNEL = ''
export const SOFT_CODE_360 = '' // 360渠道的softcode
/**
 * 软件配置
 */
export const appConfig = {
  name: '轻秒图片转换器',
  logo: 'https://res.yunkun.cn/img_geshicn/img/logo_yasuo.svg',
  contact: {
    customer: 'https://res.yunkun.cn/img_geshicn/img/contact_qr_code.png', // 客服二维码
    officialAccounts: 'https://res.yunkun.cn/img_geshicn/img/qrcode_for_gzh_wx.png', // 公众号二维码
  },
  footer: {
    copyright: `Copyright © ${new Date().getFullYear()} 无锡轻秒网络科技有限公司`,
    recordNumber: '苏ICP备2023045483号-2',
    beian: '苏公网安备32021402002474',
    agreement: '/agreement_wx.html',
    privacyPolicy: '/privacy_wx.html',

  },
  companyInfo: {
    name: '无锡轻秒网络科技有限公司',
    address: 'https://res.yunkun.cn/pic_geshicn/Map.png',
    about: {
      title: '/qingmiao_logo.png',
      content: [
        {
          title: '关于轻秒',
          content: `<p>轻秒网络是一家引领未来办公方式的科技公司，使命是以轻松与高效为指南，为用户提供创新的办公软件解决方案。公司致力于打破传统工作方式的束缚，让用户在办公领域体验到轻快灵动的工作氛围。</p><p>轻秒的产品不仅仅是办公软件，更是一种办公方式的象征。通过结合轻快的设计和秒级的响应速度，让每一秒都变得更有意义。我们深信，通过我们的努力，可以让办公变得更加简单、更加高效，让用户在工作中感到更轻松和愉悦。这就是轻秒，一个不断挑战传统，引领未来的科技公司。</p><p>轻 - 如同微风拂面： “轻”就像一阵微风，清新而不拖泥带水。我们追求简洁、直观的界面，用户不需要花费过多时间去适应，仿佛轻轻一点，就能完成工作的每一个步骤。</p><p>秒 - 时光流逝如箭： “秒”则宛如时间的箭头，让用户在工作中事半功倍，像一场闪电般的响应速度和操作效率。无论是图片、音视频、文档处理……都能在瞬间完成，让用户不再感到被工作的琐碎所拖累。</p><p>“轻秒”一个集轻盈和高效于一身的办公平台，它如同你的得力助手，不仅让工作变得简单轻松，而且能够在瞬间完成你的任务，为你的工作生活注入活力和效率。</p>`
        },
        {
          title: '公司使命',
          content: `<p>轻秒的使命提供一种轻便、高效的办公体验，帮助用户更轻松地完成工作。我们致力于开发创新的办公软件，以简单直观的设计和高效的操作，满足现代职场对于简单、快捷、高效工作方式的需求。让用户在工作中感到轻松愉悦，减轻工作压力，提升工作效率。追求成为用户在办公领域中的首选，通过不断创新和提升用户体验，为用户提供更好的工作工具，助力他们在职业生涯中取得更多的成功。</p>`
        }
      ]
    }
  }
}

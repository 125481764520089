export * from './download'
export * from './getFileInfo'
export * from './changeSizeUnit'
export * from './pxielConversion'
export * from './getChannel'

export const isVertical = (width, height) => {
  return width / height <= 1
}
export const loadImage = src => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = src
    img.onload = () => {
      resolve(img)
    }
    img.onerror = () => {
      reject()
    }
  })
}
export const getRotateSize = (width, height, rotate) => {
  // 计算旋转后的宽高
  const radians = (rotate * Math.PI) / 180
  let w  = Math.abs(width * Math.cos(radians)) + Math.abs(height * Math.sin(radians));
  let h = Math.abs(width * Math.sin(radians)) + Math.abs(height * Math.cos(radians));
  return {
    width: w,
    height: h
  }
}
export const createRotateImg = async (img, rotate) => {
  const {width, height} = getRotateSize(img.width, img.height, rotate)
  return creatImg(img, width, height, false, false, rotate)
}
export const creatImg = (imgElement, width, height, flipX=false, flipY=false, rotate = 0) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = width
    canvas.height = height
    let size = getRotateSize(imgElement.width, imgElement.height, rotate)
    const scale = Math.min(width / size.width, height / size.height)
    let newWidth = imgElement.width * scale
    let newHeight = imgElement.height * scale

    // 清空 canvas
    ctx.clearRect(0, 0, width, height)

    // 填充白色背景
    ctx.fillStyle = '#FFFFFF' // 设置填充颜色为白色
    ctx.fillRect(0, 0, width, height) // 填充整个canvas

    ctx.translate(width / 2, height / 2)

      const radians = (rotate * Math.PI) / 180
      ctx.rotate(radians)
      //

    if (flipX) {
      ctx.scale(1, -1)
    }
    if (flipY) {
      ctx.scale(-1, 1)
    }
    // let fX = flipX ? 1: -1
    // let fY = flipY ? -1 : 1

    // 将图片绘制到 canvas 居中显示
    // const offsetX = (width - newWidth) / 2
    // const offsetY = (height - newHeight) / 2
    const offsetX = -newWidth / 2
    const offsetY = -newHeight / 2
    ctx.drawImage(imgElement, offsetX, offsetY, newWidth, newHeight)
    canvas.toBlob(blob => {
      resolve(blob)
    })
  })
}

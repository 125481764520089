export const headerMenus = [
  {
    title: '图片压缩',
    key: 'compress',
    category: 'imageCompress',
    children: [
      {
        title: '图片压缩',
        link: '/',
        icon: require('@/assets/img/compress_pic_icon.svg')
      },
      {
        title: 'JPG压缩',
        link: '/compress/jpg',
        icon: require('@/assets/img/compress_jpg_icon.svg')
      },
      // {
      //   title: 'GIF压缩',
      //   link: '/compress/gif',
      //   icon: require('@/assets/img/compress_gif_icon.svg')
      // },
      {
        title: 'PNG压缩',
        link: '/compress/png',
        icon: require('@/assets/img/compress_png_icon.svg')
      },
      {
        title: 'BMP压缩',
        link: '/compress/bmp',
        icon: require('@/assets/img/compress_bmp.svg')
      },
      {
        title: 'WEBP压缩',
        link: '/compress/webp',
        icon: require('@/assets/img/compress_webp_icon.svg')
      },
      {
        title: 'SVG压缩',
        link: '/compress/svg',
        icon: require('@/assets/img/compress_svg_icon.svg')
      },
      {
        title: 'AVIF压缩',
        link: '/compress/avif',
        icon: require('@/assets/img/compress_avif_icon.svg')
      },
      {
        title: '证件照压缩',
        link: '/compress/zjz',
        icon: require('@/assets/img/compress_zjz_icon.svg')
      },
      {
        title: '压缩指定大小',
        link: '/compress/size',
        icon: require('@/assets/img/compress_size_icon.svg')
      }
    ]
  },
  {
    title: '图片编辑',
    key: 'edit',
    category: 'imageEdit',
    children: [
      {
        title: '图片改尺寸',
        link: '/edit',
        icon: require('@/assets/img/edit_pic_icon.svg')
      },
      {
        title: '修改DPI',
        link: '/edit/dpi',
        icon: require('@/assets/img/dpi_icon.svg')
      },
      {
        title: '内存变大',
        link: '/edit/increase-memory',
        icon: require('@/assets/img/compress_size_icon.svg')
      },
      {
        title: '图片编辑',
        link: '/edit/size',
        icon: require('@/assets/img/image_edit.svg')
      },
      {
        title: '图片圆角',
        link: '/edit/radius',
        isFree: true,
        icon: require('@/assets/img/image_radius.svg')
      },
      {
        title: '图片翻转',
        link: '/edit/flip',
        icon: require('@/assets/img/image_flip.svg')
      },
      {
        title: '图片旋转',
        link: '/edit/rotate',
        icon: require('@/assets/img/image_rotate.svg')
      },
      {
        title: '图片拼接',
        link: '/edit/join',
        icon: require('@/assets/img/image_puzzle.svg')
      },
      {
        title: '在线证件照',
        link: '/edit/id',
        icon: require('@/assets/img/id.svg')
      },
      {
        title: '图片加水印',
        link: '/edit/watermark',
        icon: require('@/assets/img/addsy-icon.svg')
      }
    ]
  },

  {
    title: '图片AI',
    key: 'AI',
    category: 'imageAI',
    children: [
      {
        title: '图片无损放大',
        link: '/edit/fangda',
        isAi: true,
        icon: require('@/assets/img/PropertyFD.svg')
      },
      {
        title: '图片变清晰',
        link: '/edit/hd',
        isAi: true,
        icon: require('@/assets/img/PropertyHD.svg')
      },
      {
        title: '老照片修复',
        link: '/edit/laozhaopian',
        isAi: true,
        icon: require('@/assets/img/clear.svg')
      },
      {
        title: '黑白照上色',
        link: '/edit/upcolor',
        isAi: true,
        icon: require('@/assets/img/color.svg')
      },
      {
        title: '图片去水印',
        link: '/edit/remove-watermark',
        isAi: true,
        icon: require('@/assets/img/aieraser-icon.svg')
      },
    ]
  },
  {
    title: '格式转换',
    key: 'convert',
    category: 'imageConvert',
    children: [
      {
        title: '图片格式转换',
        link: '/convert',
        icon: require('@/assets/img/pic_format_icon.svg')
      },
      {
        title: 'HEIC转JPG',
        link: '/convert/heic2jpg',
        icon: require('@/assets/img/heic2jpg.svg')
      },
      {
        title: 'TIFF转JPG',
        link: '/convert/tiff2jpg',
        icon: require('@/assets/img/tiff2jpg.svg')
      },
      {
        title: '图片转ICO',
        link: '/convert/2ico',
        icon: require('@/assets/img/pic2ico.svg')
      },
      {
        title: '图片转JPG',
        link: '/convert/2jpg',
        icon: require('@/assets/img/pic2jpg.svg')
      },
      {
        title: '图片转PNG',
        link: '/convert/2png',
        icon: require('@/assets/img/pic2png.svg')
      },
      {
        title: '图片转BMP',
        link: '/convert/2bmp',
        icon: require('@/assets/img/pic2bmp.svg')
      },
      {
        title: '图片转WEBP',
        link: '/convert/2webp',
        icon: require('@/assets/img/pic2webp.svg')
      }
    ]
  },
  {
    title: 'PDF转换',
    key: 'pdf',
    category: 'pdfConvert',
    children: [
      {
        title: 'PDF压缩',
        link: '/pdf/compress',
        icon: require('@/assets/img/pdf/pdf_compress_icon.svg')
      },
      {
        title: 'PDF转Word',
        link: '/pdf/to-word',
        icon: require('@/assets/img/pdf/pdf2word_icon.svg')
      },
      {
        title: 'PDF转图片',
        link: '/pdf/to-img',
        icon: require('@/assets/img/pdf/pdf2img_icon.svg')
      },
      {
        title: 'PDF转PPT',
        link: '/pdf/to-ppt',
        icon: require('@/assets/img/pdf/pdf2ppt_icon.svg')
      },
      {
        title: 'PDF转Excel',
        link: '/pdf/to-excel',
        icon: require('@/assets/img/pdf/pdf2excel_icon.svg')
      },
      {
        title: 'Word转PDF',
        link: '/pdf/word2pdf',
        icon: require('@/assets/img/pdf/word2pdf_icon.svg')
      },
      {
        title: '图片转PDF',
        link: '/pdf/img2pdf',
        icon: require('@/assets/img/pdf/img2pdf_icon.svg')
      },
      {
        title: 'PPT转PDF',
        link: '/pdf/ppt2pdf',
        icon: require('@/assets/img/pdf/ppt2pdf_icon.svg')
      },
      {
        title: 'Excel转PDF',
        link: '/pdf/excel2pdf',
        icon: require('@/assets/img/pdf/excel2pdf_icon.svg')
      },
      {
        title: 'CAD转PDF',
        link: '/pdf/cad2pdf',
        icon: require('@/assets/img/pdf/cad2pdf_icon.svg')
      }
    ]
  },
  {
    title: 'GIF工具',
    key: 'gif',
    category: 'gifTools',
    children: [
      {
        title: 'GIF压缩',
        link: '/gif/compress',
        icon: require('@/assets/img/gif-compress.svg')
      },
      {
        title: 'GIF缩放',
        link: '/gif/scale',
        icon: require('@/assets/img/gif-scale.svg')
      },
      {
        title: 'GIF裁剪',
        link: '/gif/crop',
        icon: require('@/assets/img/gif-cropping.svg')
      },
      {
        title: 'GIF合成',
        link: '/gif/compose',
        icon: require('@/assets/img/gif-compose.svg')
      },
      {
        title: 'GIF加水印',
        link: '/gif/watermark',
        icon: require('@/assets/img/gifadd.svg')
      },
      {
        title: 'GIF分解',
        link: '/gif/decompose',
        icon: require('@/assets/img/gifresolve.svg')
      },
    ]
  }
]
